<template>
  <div>
    <div class="pa24">
      <!-- <div class="d-flex">
                <div style="margin-left: auto">
                    <el-button class="ma mr10" type="primary" @click="addParkFun" :disabled="!companyId">关联园区
                    </el-button>
                </div>
            </div> -->
      <commonTable
        :tableData="tableData"
        :loading="tableDataLoading"
        :paginationVisible="false"
      >
        <template v-slot:table>
          <el-table-column
            prop="shopName"
            align="center"
            label="门店名称"
            show-overflow-tooltip
          />
          <el-table-column
            prop="shopPhone"
            align="center"
            label="门店电话"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            label="进驻园区/社团"
            show-overflow-tooltip
            ><template slot-scope="scope">
              <div v-if="scope.row.parkName">
                {{ scope.row.parkName }}
              </div>
              <div v-else>
                {{ scope.row.associationName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="进驻类型"
            show-overflow-tooltip
            width="80"
            ><template slot-scope="scope">
              <el-tag v-if="scope.row.parkId">园区</el-tag>
              <el-tag v-else-if="scope.row.associationId">社团</el-tag>
              <el-tag v-else>-</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="typeTitle"
            align="center"
            label="代理类型"
            show-overflow-tooltip
          />
          <el-table-column
            prop="endTime"
            align="center"
            label="到期时间"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.status === 1">
                {{ scope.row.endTime ? scope.row.endTime : "待付款" }}
              </div>
              <div v-else>
                {{ scope.row.endTime ? scope.row.endTime : "还未通过审核" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            label="审核状态"
            width="80"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <!-- <div class="corange"
                                v-if="scope.row.status == 2 || scope.row.status == 4 || scope.row.status == 5">{{
                                statusObj[scope.row.status]
                                }}</div>
                            <div v-else>{{ statusObj[scope.row.status] }}</div> -->
              <el-tag
                type="danger"
                v-if="
                  scope.row.status == 2 ||
                  scope.row.status == 4 ||
                  scope.row.status == 5
                "
                >{{ statusObj[scope.row.status] }}</el-tag
              >
              <el-tag v-else>{{ statusObj[scope.row.status] }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="showApplyModelFun(scope.row)"
                >详 情</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.status === 3"
                @click="showUpdateModelFun(scope.row)"
              >
                编 辑
              </el-button>
              <el-button
                type="text"
                v-else
                @click="showUpdateModelFun(scope.row)"
                >修改申请</el-button
              >
            </template>
          </el-table-column>
        </template>
      </commonTable>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="修改申请信息"
      :visible.sync="showUpdateModel"
      :destroy-on-close="true"
      width="80%"
    >
      <el-form
        :model="formData"
        :rules="rules"
        ref="formData"
        label-width="150px"
        v-loading="updateModelLoading"
      >
        <el-form-item label="LOGO：" prop="shopLogo" required>
          <el-upload
            :action="uploadUrl"
            list-type="picture-card"
            :class="{ disabled: logoUploadDisabled }"
            :on-remove="logoHandleRemove"
            :on-success="logoHandleSuccess"
            :file-list="logoImg"
            :limit="1"
          >
            <i slot="default" class="el-icon-plus"></i>
            <!-- <div slot="tip" class="el-upload__tip">上传图片</div> -->
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="门店名称：" prop="shopName">
              <el-input
                v-model="formData.shopName"
                placeholder="请输入门店名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="门店电话：" prop="shopPhone">
              <el-input
                v-model="formData.shopPhone"
                placeholder="请输入门店电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="推荐人电话：" prop="invitePhone">
              <el-input
                v-model="formData.invitePhone"
                placeholder="请输入推荐人电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="代理类型：" prop="typeLinkIdList">
              <el-select
                class="w100p"
                v-model="formData.typeLinkIdList"
                multiple
                filterable
                remote
                placeholder="请选择代理类型"
              >
                <el-option
                  v-for="item in typeData"
                  :disabled="item.disabled"
                  :key="item.linkId"
                  :label="item.typeName"
                  :value="String(item.linkId)"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="门店形象照：" required prop="shopPicture">
              <el-upload
                :action="uploadUrl"
                class="pictureImg"
                list-type="picture-card"
                :class="{ disabled: pictureUploadDisabled }"
                :on-success="pictureHandleSuccess"
                :on-remove="pictureHandleRemove"
                :file-list="pictureImg"
                :limit="5"
              >
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div slot="tip" class="el-upload__tip">上传图片</div> -->
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="免单承诺：" required prop="shopPromise">
              <el-upload
                :action="uploadUrl"
                class="promiseImg"
                list-type="picture-card"
                :class="{ disabled: promiseUploadDisabled }"
                :on-remove="promiseHandleRemove"
                :on-success="promiseHandleSuccess"
                :file-list="promiseImg"
                :limit="1"
              >
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div slot="tip" class="el-upload__tip">上传图片</div> -->
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">到店指引</el-divider>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="营业时间：" prop="businessHours">
              <el-input
                v-model="formData.businessHours"
                placeholder="请输入营业时间"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="联系电话：" prop="telephone">
              <el-input
                type="tel"
                v-model="formData.telephone"
                placeholder="请输入联系电话"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="门店地址" prop="areaCode">
              <el-select
                class="w130"
                v-model="province"
                placeholder="省"
                filterable
                @change="getTbArea(1, $event)"
              >
                <el-option
                  v-for="item in provinceData"
                  :key="item.areaCode"
                  :label="item.name"
                  :value="item.areaCode"
                >
                </el-option>
              </el-select>
              <el-select
                class="w130"
                v-model="city"
                placeholder="市"
                filterable
                @change="getTbArea(2, $event)"
              >
                <el-option
                  v-for="item in cityData"
                  :key="item.areaCode"
                  :label="item.name"
                  :value="item.areaCode"
                >
                </el-option>
              </el-select>
              <el-select
                class="w130"
                v-model="area"
                placeholder="区/县"
                filterable
                @change="getTbArea(3, $event)"
              >
                <el-option
                  v-for="item in areaData"
                  :key="item.areaCode"
                  :label="item.name"
                  :value="item.areaCode"
                >
                </el-option>
              </el-select>
              <el-select
                class="w130"
                v-model="formData.areaCode"
                placeholder="镇/街道"
                filterable
              >
                <el-option
                  v-for="item in streetData"
                  :key="item.areaCode"
                  :label="item.name"
                  :value="item.areaCode"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="详情地址：" prop="address">
              <el-input
                v-model="formData.address"
                placeholder="请输入详情地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="地标信息：" prop="message">
              <el-input
                v-model="formData.message"
                placeholder="请输入地标信息"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="productType">
          <el-tab-pane label="折扣商品" name="discount"> </el-tab-pane>
          <el-tab-pane label="免单商品" name="free"> </el-tab-pane>
        </el-tabs>
        <div v-if="productType === 'discount'">
          <el-row class="mt20">
            <el-col :lg="24">
              <el-form-item label="商品让利率：">
                <el-radio-group
                  v-model="formData.discountType"
                  @change="changeDiscountType"
                >
                  <el-radio :label="0">全店商品</el-radio>
                  <el-radio :label="1">部分商品</el-radio>
                </el-radio-group>
                <el-input
                  class="w130 suffixInput ml10"
                  v-if="formData.discountType === 0"
                  type="number"
                  @change="changeDiscountScale"
                  v-model="discountScale"
                  placeholder="让利率"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="24">
              <el-form-item label="用户消费须知：" prop="discountShouldKnow">
                <el-input
                  v-model="formData.discountShouldKnow"
                  :rows="5"
                  type="textarea"
                  placeholder="请输入用户消费须知"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">折扣商品</el-divider>
          <div class="myCardView mb20">
            <div
              class="myCardItem hover_pointer"
              @click="addProduct('discount')"
            >
              <el-card :body-style="{ padding: '10px' }" shadow="always">
                <div class="flex-c-c" style="height: 60px; font-size: 30px">
                  <i class="el-icon-plus"></i>
                </div>
              </el-card>
            </div>
            <div v-for="(row, index) in formData.discountList" :key="index">
              <div class="myCardItem" v-if="!row.isDel">
                <el-card :body-style="{ padding: '10px' }" shadow="always">
                  <div class="d-flex">
                    <div class="leftImg flex-c-c">
                      <img :src="row.goodsImg" alt="" />
                    </div>
                    <div class="rightText">
                      <div class="pName text-overflow">{{ row.goodsName }}</div>
                      <div class="cale">
                        {{ row.discountScale | getDiscount }}折
                      </div>
                      <div class="pPrice">
                        <span class="i">￥</span>{{ row.goodsPrice }}
                      </div>
                      <div class="updateDel" @click="delProductFun(row, index)">
                        删 除
                      </div>
                      <div
                        class="updateBtn"
                        @click="updateProductFun(row, index)"
                      >
                        编 辑
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </div>
        <div v-if="productType === 'free'">
          <el-row class="mt20">
            <el-col :lg="24">
              <el-form-item label="是否参与免单：">
                <el-radio-group v-model="formData.isFree">
                  <el-radio :label="0">不参与</el-radio>
                  <el-radio :label="1">参与</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="formData.isFree == 1">
            <el-col :lg="24">
              <el-form-item label="免单活动日期：">
                <el-date-picker
                  v-model="freeTime"
                  type="daterange"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  align="right"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="formData.isFree == 1">
            <el-col :lg="24">
              <el-form-item label="用户消费须知：" prop="freeShouldKnow">
                <el-input
                  v-model="formData.freeShouldKnow"
                  type="textarea"
                  :rows="5"
                  placeholder="请输入用户消费须知"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider v-if="formData.isFree == 1" content-position="left"
            >免单商品</el-divider
          >
          <div v-if="formData.isFree == 1" class="myCardView">
            <div class="myCardItem hover_pointer" @click="addProduct('free')">
              <el-card :body-style="{ padding: '10px' }" shadow="always">
                <div class="flex-c-c" style="height: 60px; font-size: 30px">
                  <i class="el-icon-plus"></i>
                </div>
              </el-card>
            </div>
            <div v-for="(row, index) in formData.freeList" :key="index">
              <div class="myCardItem" v-if="!row.isDel">
                <el-card :body-style="{ padding: '10px' }" shadow="always">
                  <div class="d-flex">
                    <div class="leftImg flex-c-c">
                      <img :src="row.goodsImg" alt="" />
                    </div>
                    <div class="rightText">
                      <div class="pName text-overflow">{{ row.goodsName }}</div>
                      <div class="pPrice">
                        <span class="i">￥</span>{{ row.goodsPrice }}
                      </div>
                      <div class="updateDel" @click="delProductFun(row, index)">
                        删 除
                      </div>
                      <div
                        class="updateBtn"
                        @click="updateProductFun(row, index)"
                      >
                        编 辑
                      </div>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="updateFun()" :loading="loadingBtn"
            >提 交
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      title="详 情"
      :visible.sync="showShopApplyModel"
      :destroy-on-close="true"
      width="50%"
      center
    >
      <el-form
        :model="shopApplyDetails"
        ref="shopApplyDetails"
        label-width="150px"
        v-loading="shopDetailsLoading"
      >
        <el-form-item label="">
          <img class="logo" :src="shopApplyDetails.shopLogo" alt="" />
        </el-form-item>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="门店名称：">
              <div>{{ shopApplyDetails.shopName }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="门店电话：">
              <div>{{ shopApplyDetails.shopPhone }}</div>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="推荐人电话：">
              <div>{{ shopApplyDetails.invitePhone }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="联系人：">
              <div>{{ shopApplyDetails.name }}</div>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="联系电话：">
              <div>{{ shopApplyDetails.phone }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="代理类型：">
              <el-tag
                class="mr5"
                v-for="(row, index) in shopApplyDetails.typeList"
                size="medium"
                :key="index"
              >
                {{ row.typeName }}</el-tag
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="门店形象照：">
              <el-button
                type="primary"
                @click="
                  showImgModel('门店形象照', shopApplyDetails.shopPicture)
                "
                >查看照片
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="营业执照：">
              <el-button
                type="primary"
                @click="showImgModel('营业执照', shopApplyDetails.shopLicense)"
                >查看执照
              </el-button>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="免单承诺：">
              <el-button
                type="primary"
                @click="showImgModel('免单承诺', shopApplyDetails.shopPromise)"
                >查看承诺
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">到店指引</el-divider>
        <el-row>
          <el-col :lg="12">
            <el-form-item label="营业时间：">
              <div>
                {{ shopApplyDetails.businessHours }}
              </div>
            </el-form-item>
          </el-col>
          <el-col :lg="12">
            <el-form-item label="联系电话：">
              <div>
                {{ shopApplyDetails.telephone }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="门店地址：">
              <div>
                {{ shopApplyDetails.mergerName }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="详情地址：">
              <div>
                {{ shopApplyDetails.address }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="24">
            <el-form-item label="地标信息：">
              <div>
                {{ shopApplyDetails.message }}
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="detailsProductType">
          <el-tab-pane label="折扣商品" name="discount"> </el-tab-pane>
          <el-tab-pane
            label="免单商品"
            v-if="shopApplyDetails.isFree == 1"
            name="free"
          >
          </el-tab-pane>
        </el-tabs>
        <div v-if="detailsProductType === 'discount'">
          <div
            class="mt10 mb10 ml10"
            v-if="
              shopApplyDetails.discountType == 0 &&
              shopApplyDetails.discountScale > 0
            "
          >
            商品折扣：{{ discountTypeObj[shopApplyDetails.discountType]
            }}{{ shopApplyDetails.discountScale | getDiscount }}折
          </div>
          <el-alert
            :title="'用户消费须知：' + shopApplyDetails.discountShouldKnow"
            type="warning"
            show-icon
          >
          </el-alert>
          <div
            class="myCardView"
            v-if="
              shopApplyDetails.discountList &&
              shopApplyDetails.discountList.length > 0
            "
          >
            <div
              class="myCardItem"
              v-for="(row, index) in shopApplyDetails.discountList"
              :key="index"
            >
              <el-card :body-style="{ padding: '10px' }" shadow="always">
                <div class="d-flex">
                  <div class="leftImg flex-c-c">
                    <img :src="row.goodsImg" alt="" />
                  </div>
                  <div class="rightText">
                    <div class="pName text-overflow">{{ row.goodsName }}</div>
                    <div
                      class="cale"
                      v-if="
                        shopApplyDetails.discountType == 1 &&
                        row.discountScale > 0
                      "
                    >
                      {{ row.discountScale | getDiscount }}折
                    </div>
                    <div class="pPrice">
                      <span class="i">￥</span>{{ row.goodsPrice }}
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
          <div v-else class="myCardView pt10 flex-c-c">无商品</div>
        </div>
        <div v-if="detailsProductType === 'free'">
          <div class="mt10 mb10 ml10">
            免单活动日期：{{ shopApplyDetails.freeStartTime }} ~
            {{ shopApplyDetails.freeEndTime }}
          </div>
          <el-alert
            :title="'用户消费须知：' + shopApplyDetails.freeShouldKnow"
            type="warning"
            show-icon
          >
          </el-alert>
          <div
            class="myCardView"
            v-if="
              shopApplyDetails.freeList && shopApplyDetails.freeList.length > 0
            "
          >
            <div
              class="myCardItem"
              v-for="(row, index) in shopApplyDetails.freeList"
              :key="index"
            >
              <el-card :body-style="{ padding: '10px' }" shadow="always">
                <div class="d-flex">
                  <div class="leftImg flex-c-c">
                    <img :src="row.goodsImg" alt="" />
                  </div>
                  <div class="rightText">
                    <div class="pName text-overflow">{{ row.goodsName }}</div>
                    <div class="pPrice">
                      <span class="i">￥</span>{{ row.goodsPrice }}
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </div>
          <div v-else class="myCardView pt10 flex-c-c">无商品</div>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :title="showImgTitle"
      :visible.sync="showImg"
      :destroy-on-close="true"
      center
    >
      <el-carousel :autoplay="false" height="500px">
        <el-carousel-item v-for="item in imgUrlArr" :key="item">
          <!-- <img height="100%" width="100%" :src="item" alt=""> -->
          <el-image
            style="width: 100%; height: 100%"
            :src="item"
            fit="scale-down"
          ></el-image>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <set-product
      ref="setProduct"
      :defaultData="udpateProduct"
      @submitProduct="submitProduct"
      :isScale="formData.discountType == 1 && productType === 'discount'"
    />
  </div>
</template>

<script>
import {
  getParkIntoCompanyList,
  setParkIntoShop,
  getParkIntoCompany,
} from "@/api/propertyServices";
import { getParkShopTypeLinkList, getTbArea } from "@/api/parkManagement";
import commonTable from "@/components/common/commonTable";
import setProduct from "./setProduct";
export default {
  name: "corporateInformation",
  components: { commonTable, setProduct },
  data() {
    return {
      loadingBtn: false,
      //--修改申请配置
      province: "", //省
      city: "", //市
      area: "", //区
      provinceData: [], //省数据
      cityData: [], //市数据
      areaData: [], //区数据
      streetData: [], //街道数据
      updateIndex: "", //编辑商品的下标
      productFormType: "add",
      pickerOptions: {
        //选择免单日期快捷配置
        shortcuts: [
          {
            text: "一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      productType: "discount",
      companyId: 0, //公司id
      uploadUrl: this.$store.state.uploadingUrl,
      freeTime: "", //免单活动时间
      udpateProduct: {
        //用于商品编辑初始化
        goodsId: "",
        goodsImg: "",
        goodsName: "",
        goodsPrice: "",
        discountScale: "",
      },
      formData: {
        //关联信息
        intoId: "",
        messageId: "",
        shopName: "", //门店名称
        shopPhone: "", //门店电话
        invitePhone: "", //推荐电话
        shopLicense: "", //营业执照
        shopLogo: "", //门店logo
        shopPicture: "", //门店形象照
        shopPromise: "", //免单承诺 图片
        typeLinkIdList: [], //代理类型
        parkId: "",
        associationId: "",
        shopCode: "", //信用代码
        //---折扣商品配置
        discountType: 0, //折扣类型 0：全店商品，1：部分商品
        discountList: [], //折扣商品 goodsId/商品id goodsImg/商品图片 goodsName/商品名称 goodsPrice/商品价格 discountScale/让利比率
        discountShouldKnow: "", //折扣用户消费需知
        //---免单商品配置
        isFree: 1, //是否参与免单 0：不参与，1：参与
        freeStartTime: "", //免单开始时间
        freeEndTime: "", //免单结束时间
        freeList: [], //免单商品 goodsId/商品id goodsImg/商品图片 goodsName/商品名称 goodsPrice/商品价格
        freeShouldKnow: "", //免单用户消费需知
        //---到店指引
        businessHours: "", //营业时间
        telephone: "", //联系电话
        areaCode: "", //门店地址
        address: "", //详情地址
        message: "", //地标信息
      },
      discountScale: 0, //全店折扣让利率
      logoImg: [],
      pictureImg: [],
      promiseImg: [],
      showUpdateModel: false, //设置显示进驻表单
      rules: {
        //验证提交数据
        shopLogo: [{ required: true, message: "请上传LOGO" }],
        shopName: [
          { required: true, message: "请输入门店名称", trigger: "blur" },
        ],
        shopPhone: [
          { required: true, message: "请输入门店电话", trigger: "blur" },
        ],
        typeLinkIdList: [
          { required: true, message: "请选择代理类型", trigger: "change" },
        ],
        shopPicture: [{ required: true, message: "请上传形象照" }],
        shopPromise: [{ required: true, message: "请上传免单承诺" }],
        businessHours: [
          { required: true, message: "请输入营业时间", trigger: "blur" },
        ],
        telephone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        areaCode: [
          {
            required: true,
            message: "请选择详情地址到镇/街道",
            trigger: "change",
          },
        ],
        address: [
          { required: true, message: "请输入详情地址", trigger: "blur" },
        ],
        message: [
          { required: true, message: "请输入地标信息", trigger: "blur" },
        ],
        discountShouldKnow: [
          { required: true, message: "请输入折扣需知", trigger: "blur" },
        ],
        freeShouldKnow: [
          { required: true, message: "请输入免单需知", trigger: "blur" },
        ],
      },
      //--列表配置
      statusObj: {
        0: "待审核",
        1: "待付款",
        2: "不通过",
        3: "正常",
        4: "到期",
        5: "禁用",
      },
      typeData: [],
      tableData: [], //周边商家数据
      tableDataLoading: true, //周边商家数据加载状态
      //--详情配置
      showImg: false,
      imgUrlArr: "",
      showImgTitle: "",
      detailsProductType: "discount",
      updateModelLoading: false,
      showShopApplyModel: false, //详情模态框显示状态
      shopDetailsLoading: false, //详情模态框加载状态
      shopApplyDetails: {
        //详情详情
        shopName: "",
        shopPhone: "",
        name: "",
        phone: "",
        invitePhone: "",
        typeList: [],
        shopLogo: "",
        shopPicture: "",
        shopLicense: "",
        shopPromise: "",
        //折扣商品
        discountType: "",
        discountList: [],
        discountScale: 0,
        discountShouldKnow: "",
        //免单商品
        isFree: "",
        freeList: [],
        freeShouldKnow: "",
        freeStartTime: "",
        freeEndTime: "",
        businessHours: "",
        telephone: "",
        address: "",
        status: "",
        mergerName: "",
        message: "",
      },
      ossUrl: this.$store.state.ossUrl,
      discountTypeObj: {
        0: "全店商品",
        1: "部分商品",
      },
    };
  },
  computed: {
    logoUploadDisabled() {
      //logo图状态
      return this.logoImg.length !== 0;
    },
    pictureUploadDisabled() {
      //形象图状态
      return this.pictureImg.length >= 5;
    },
    promiseUploadDisabled() {
      //免承诺图状态
      return this.promiseImg.length !== 0;
    },
  },
  async mounted() {
    this.companyId = this.$store.state.loginRoot.userInfo.companyId;
    await this.getTbArea(0);
    this.getParkIntoCompanyList();
  },
  filters: {
    getDiscount(n) {
      return ((1 - n) * 10).toFixed(1);
    },
  },
  methods: {
    /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
     * @parentCode 父标签
     * @method 获取地区数据做层级处理
     */
    async getTbArea(level = 0, parentCode) {
      let params = {
        level: level,
        parentCode: parentCode,
      };
      if (level === 0) {
        this.cityData = [];
        this.areaData = [];
        this.streetData = [];
        this.province = ""; //省
        this.city = ""; //市
        this.area = ""; //区
        this.formData.areaCode = ""; //街道
      }
      if (level === 1) {
        this.areaData = [];
        this.streetData = [];
        this.city = ""; //市
        this.area = ""; //区
        this.formData.areaCode = ""; //街道
      }
      if (level === 2) {
        this.streetData = [];
        this.area = ""; //区
        this.formData.areaCode = ""; //街道
      }
      if (level === 3) {
        this.formData.areaCode = ""; //街道
      }
      let res = await getTbArea(params);
      if (level === 0) {
        this.provinceData = res.data;
      }
      if (level === 1) {
        this.cityData = res.data;
      }
      if (level === 2) {
        this.areaData = res.data;
      }
      if (level === 3) {
        this.streetData = res.data;
      }
    },
    /**@level 0=省/直辖市/自治区/特别行政区,1=市,2=区,3=镇,4=居委会
     * @parentCode 父标签
     * @method 获取地区数据
     */
    async getAreaData(level = 0, parentCode) {
      let params = {
        level: level,
        parentCode: parentCode,
      };
      let res = await getTbArea(params);
      if (level === 0) {
        this.provinceData = res.data;
      }
      if (level === 1) {
        this.cityData = res.data;
      }
      if (level === 2) {
        this.areaData = res.data;
      }
      if (level === 3) {
        this.streetData = res.data;
      }
    },
    /**@method 监听折扣类型 */
    changeDiscountType(val) {
      let discountList = [];
      if (val === 0) {
        //全店折扣
        for (let row of this.formData.discountList) {
          row.discountScale = this.discountScale / 100;
          discountList.push(row);
        }
      } else {
        //部分商品折扣
        for (let row of this.formData.discountList) {
          row.discountScale = 0;
          discountList.push(row);
        }
      }
      this.formData.discountList = discountList;
    },
    /**@method 监听折扣 */
    changeDiscountScale(val) {
      let discountList = [];
      for (let row of this.formData.discountList) {
        row.discountScale = val / 100;
        discountList.push(row);
      }
      this.formData.discountList = discountList;
    },
    /**@method 添加商品 */
    submitProduct(product) {
      if (this.productFormType == "add") {
        if (this.productType === "discount") {
          if (this.formData.discountType === 0) {
            product.discountScale = this.discountScale / 100;
          } else {
            product.discountScale = product.discountScale / 100;
          }
          this.formData.discountList.unshift(product);
        } else if (this.productType === "free") {
          delete product.discountScale;
          this.formData.freeList.unshift(product);
        }
      } else {
        if (this.productType === "discount") {
          if (this.formData.discountType === 0) {
            product.discountScale = this.discountScale / 100;
          } else {
            product.discountScale = product.discountScale / 100;
          }
          this.$set(this.formData.discountList, this.updateIndex, product);
        } else if (this.productType === "free") {
          delete product.discountScale;
          this.$set(this.formData.freeList, this.updateIndex, product);
        }
      }
    },
    /**@method 删除商品 */
    delProductFun(row, index) {
      if (this.productType === "discount") {
        if (this.formData.discountList[index].goodsId) {
          this.formData.discountList[index].isDel = 1;
        } else {
          this.formData.discountList.splice(index, 1);
        }
        this.$set(
          this.formData.discountList,
          index,
          this.formData.discountList[index]
        );
        //-console.log(this.formData.discountList);
      } else if (this.productType === "free") {
        if (this.formData.freeList[index].goodsId) {
          this.formData.freeList[index].isDel = 1;
        } else {
          this.formData.freeList.splice(index, 1);
        }
        this.$set(this.formData.freeList, index, this.formData.freeList[index]);
        //-console.log(this.formData.freeList);
      }
    },
    /**@method 显示编辑商品表单 */
    updateProductFun(row, index) {
      this.udpateProduct = {
        goodsId: row.goodsId,
        goodsImg: row.goodsImg,
        goodsName: row.goodsName,
        goodsPrice: row.goodsPrice,
        discountScale: row.discountScale * 100,
        isDel: row.isDel,
      };
      this.updateIndex = index;
      this.productFormType = "update";
      this.$refs.setProduct.dialogTitle = "编辑商品";
      this.$refs.setProduct.showDialog = true;
    },
    /**@method 显示添加商品表单 */
    addProduct() {
      this.udpateProduct = {
        goodsId: "",
        goodsImg: "",
        goodsName: "",
        goodsPrice: "",
        discountScale: "",
      };
      this.productFormType = "add";
      this.$refs.setProduct.dialogTitle = "添加商品";
      this.$refs.setProduct.showDialog = true;
    },
    /**@method 删除免单承诺图片 */
    promiseHandleRemove(file, fileList) {
      //-console.log(file, fileList);
      this.promiseImg = [];
      this.formData.shopPromise = "";
    },
    /**@method 上传免单承诺图片完成回调 */
    promiseHandleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      //-console.log('file', file)
      this.formData.shopPromise = this.ossUrl + file.response.data;
      //-console.log(this.companyMsg)
      this.promiseImg = fileList;
    },
    /**@method 删除形象照图片 */
    pictureHandleRemove(file, fileList) {
      this.formData.shopPicture = "";
    },
    /**@method 上传形象照图片回调 */
    pictureHandleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.pictureImg = fileList;
    },
    /**@method 删除形象照图片 */
    logoHandleRemove(file, fileList) {
      //-console.log(file, fileList);
      this.logoImg = [];
      this.formData.shopLogo = "";
    },
    /**@method 上传LOGO完成回调 */
    logoHandleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      //-console.log('file', file)
      this.formData.shopLogo = this.ossUrl + file.response.data;
      //-console.log(this.companyMsg)
      this.logoImg = fileList;
    },
    /**@method 编辑进驻商家信息 */
    showUpdateModelFun(row) {
      this.showUpdateModel = true;
      let params = {
        typeUse: 1,
      };
      if (row.parkId) {
        this.formData.parkId = row.parkId;
        delete this.formData.associationId;
        params.parkId = row.parkId;
      } else {
        this.formData.associationId = row.associationId;
        delete this.formData.parkId;
        params.associationId = row.associationId;
      }
      this.updateModelLoading = true;
      Promise.all([
        new Promise((open) => {
          getParkShopTypeLinkList(params).then((typeRes) => {
            if (typeRes.data) {
              let typeData = [];
              for (let row of typeRes.data) {
                if (row.linkId) {
                  typeData.push(row);
                }
              }
              this.typeData = typeData;
            }
            open();
          });
        }),
        new Promise((open) => {
          getParkIntoCompany({ intoId: row.intoId }).then((res) => {
            this.shopDetailsLoading = false;
            this.updateModelLoading = false;
            if (res.data) {
              let data = res.data;
              let province = data.tbAreaFiveModelList
                ? data.tbAreaFiveModelList[0]
                : ""; //省
              let city = data.tbAreaFiveModelList
                ? data.tbAreaFiveModelList[1]
                : ""; //市
              let area = data.tbAreaFiveModelList
                ? data.tbAreaFiveModelList[2]
                : ""; //区
              if (province) {
                this.province = province.areaCode;
                this.getAreaData(1, this.province);
              }
              if (city) {
                this.city = city.areaCode;
                this.getAreaData(2, this.city);
              }
              if (area) {
                this.area = area.areaCode;
                this.getAreaData(3, this.area);
              }
              this.formData = {
                intoId: data.intoId,
                messageId: data.messageId,
                shopName: data.shopName, //门店名称
                shopPhone: data.shopPhone, //门店电话
                invitePhone: data.invitePhone, //推荐电话
                shopLogo: data.shopLogo, //门店logo
                shopPicture: data.shopPicture, //门店形象照
                shopPromise: data.shopPromise, //免单承诺 图片
                shopLicense: data.shopLicense, //	营业执照
                typeLinkIdList: [], //代理类型
                parkId: data.parkId,
                associationId: data.associationId,
                shopCode: data.shopCode,
                //---折扣商品配置
                discountType: data.discountType, //折扣类型 0：全店商品，1：部分商品
                discountList: data.discountList ? data.discountList : [], //折扣商品 goodsId/商品id goodsImg/商品图片 goodsName/商品名称 goodsPrice/商品价格 discountScale/让利比率
                discountShouldKnow: data.discountShouldKnow, //折扣用户消费需知
                //---免单商品配置
                isFree: data.isFree, //是否参与免单
                freeStartTime: data.freeStartTime, //免单开始时间
                freeEndTime: data.freeEndTime, //免单结束时间
                freeList: data.freeList ? data.freeList : [], //免单商品 goodsId/商品id goodsImg/商品图片 goodsName/商品名称 goodsPrice/商品价格
                freeShouldKnow: data.freeShouldKnow, //免单用户消费需知
                //---到店指引
                businessHours: data.businessHours, //营业时间
                telephone: data.telephone, //联系电话
                areaCode: data.areaCode, //门店地址
                address: data.address, //详情地址
                message: data.message, //地标信息
              };
              if (data.associationId) {
                this.formData.associationId = data.associationId;
              } else if (data.parkId) {
                this.formData.parkId = data.parkId;
              }
              if (data.discountType === 0 && data.discountList) {
                this.discountScale = data.discountList[0].discountScale * 100;
              }
              this.logoImg = [{ url: data.shopLogo }];
              this.pictureImg = [];
              if (data.shopPicture) {
                for (let imgUrl of data.shopPicture.split(",")) {
                  this.pictureImg.push({ url: imgUrl });
                }
              }
              if (data.shopPromise) {
                this.promiseImg = [{ url: data.shopPromise }];
              } else {
                this.promiseImg = [];
              }
              if (data.freeStartTime && data.freeEndTime) {
                this.freeTime = [data.freeStartTime, data.freeEndTime]; //免单时间处理
              }
              if (data.typeLinkIdList) {
                this.formData.typeLinkIdList = [];
                for (let id of data.typeLinkIdList) {
                  this.formData.typeLinkIdList.push(String(id));
                }
              }
            }
            open(res.data);
          });
        }),
      ]).then((value) => {
        if (value[1].typeList) {
          for (let row of value[1].typeList) {
            let isAdd = true;
            for (let item of this.typeData) {
              if (row.linkId == item.linkId) {
                isAdd = false;
              }
            }
            if (isAdd) {
              row.disabled = true;
              this.typeData.push(row);
            }
          }
        }
      });
    },
    /**@method 修改申请信息 */
    updateFun() {
      let that = this;
      let shopPicture = [];
      for (let file of that.pictureImg) {
        if ("response" in file) {
          shopPicture.push(that.ossUrl + file.response.data);
        } else {
          shopPicture.push(file.url);
        }
      }
      this.formData.shopPicture = shopPicture.join(",");
      that.$refs.formData.validate((valid) => {
        if (valid) {
          if (
            !that.formData.discountList &&
            that.formData.discountList.length == 0
          ) {
            that.$message.error("请添加折扣商品！");
            return;
          }
          if (that.formData.isFree == 1) {
            if (!that.formData.freeList && that.formData.freeList.length == 0) {
              that.$message.error("请添加免单商品！");
              return;
            }
          }
          let params = {
            ...that.formData,
            companyId: that.companyId,
          };
          if (that.freeTime && that.freeTime.length == 2) {
            params.freeStartTime = that.freeTime[0].slice(0, 10) + " 00:00:00";
            params.freeEndTime = that.freeTime[1].slice(0, 10) + " 23:59:59";
          }
          that.loadingBtn = true;
          setParkIntoShop(params)
            .then((res) => {
              that.loadingBtn = false;
              that.$message({
                message: "修改申请完成",
                type: "success",
              });
              that.showUpdateModel = false;
              that.getParkIntoCompanyList();
            })
            .catch((err) => {
              that.loadingBtn = false;
              if (err.code == 201) {
                that.$message.error(err.message);
              }
            });
        }
      });
    },
    /**@method 获取进驻周边商家列表 */
    getParkIntoCompanyList() {
      let that = this;
      that.tableDataLoading = true;
      getParkIntoCompanyList({
        companyId: that.companyId,
        isGroup: 0,
        isPage: 0,
        intoType: 1,
      })
        .then((res) => {
          let tableData = [];
          for (let row of res.data) {
            if (row.typeList) {
              let typeList = [];
              for (let child of row.typeList) {
                typeList.push(child.typeName);
              }
              row.typeTitle = typeList.join("、");
            }
            tableData.push(row);
          }
          that.tableData = tableData;
          that.tableDataLoading = false;
        })
        .catch((err) => {
          that.tableDataLoading = false;
          that.tableData = [];
        });
    },
    /**@method 显示图片 */
    showImgModel(title, url) {
      this.showImgTitle = title;
      this.showImg = true;
      this.imgUrlArr = url.split(",");
    },
    /**@method 显示详情
     * @param {Object} row - 当前点击行的值
     */
    showApplyModelFun(row) {
      this.showShopApplyModel = true;
      this.shopDetailsLoading = true;
      getParkIntoCompany({ intoId: row.intoId }).then((res) => {
        this.shopDetailsLoading = false;
        if (res.data) {
          let data = res.data;
          this.shopApplyDetails = {
            shopName: data.shopName,
            shopPhone: data.shopPhone,
            name: data.name,
            phone: data.phone,
            invitePhone: data.invitePhone,
            typeList: data.typeList,
            shopLogo: data.shopLogo,
            shopPicture: data.shopPicture,
            shopLicense: data.shopLicense,
            shopPromise: data.shopPromise,
            //折扣商品
            //审核表discountScale已废弃，统一使用商品中的discountScale
            discountType: data.discountType,
            discountList: data.discountList,
            discountScale:
              data.discountList && data.discountList.length > 0
                ? data.discountList[0].discountScale
                : 0,
            discountShouldKnow: data.discountShouldKnow,
            //免单商品
            isFree: data.isFree,
            freeList: data.freeList,
            freeShouldKnow: data.freeShouldKnow,
            freeStartTime: data.freeStartTime,
            freeEndTime: data.freeEndTime,
            businessHours: data.businessHours,
            telephone: data.telephone,
            address: data.address,
            status: data.status,
            mergerName: data.mergerName,
            message: data.message,
          };
        }
      });
    },
  },
};
</script>
<style>
.pictureImg .el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 98px !important;
}

.promiseImg .el-upload--picture-card {
  width: 160px !important;
  height: 120px !important;
  line-height: 116px !important;
}

.pictureImg .el-upload-list--picture-card .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
}

.promiseImg .el-upload-list--picture-card .el-upload-list__item {
  width: 160px !important;
  height: 120px !important;
}
</style>
<style lang="scss" scoped>
.logo {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

/deep/ .disabled .el-upload--picture-card {
  display: none;
}

.corporateInformation-view {
  padding: 16px 24px;
}

/deep/.el-tabs__content {
  background: #ffffff;
}

.ruleForm {
  margin-top: 24px;
}

.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}

.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

.myCardView {
  max-height: 178px;
  padding-bottom: 10px;
  overflow-y: auto;
  border: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
}

.myCardItem {
  width: 230px;
  margin: 10px;
  margin-bottom: 0px;
}

.leftImg {
  font-size: 26px;
  width: 60px;
  height: 60px;
  color: #fff;

  img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
  }
}

.rightText {
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  width: 130px;

  .cale {
    font-size: 12px;
    color: #999;
  }

  .pName {
    line-height: 20px;
  }

  .pPrice {
    .i {
      font-size: 13px;
    }

    position: absolute;
    bottom: 0;
    color: #e03436;
  }
}

.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }
}

.updateDel {
  color: #fd634e;
  position: absolute;
  right: 0px;
  top: 20px;
  cursor: pointer;
  font-size: 13px;
}

.updateBtn {
  color: #51cbcd;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  font-size: 13px;
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}
</style>